import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import "@/assets/global.css"
import axios from 'axios'
import * as echarts from "echarts";
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
Vue.config.productionTip = false
// 把echarts挂载到vue对象
Vue.prototype.$echarts = echarts
// 把axios挂载到vue对象
Vue.prototype.$http = axios;
// 设置路径的默认前缀
axios.defaults.baseURL = "https://jw.aichitu.com:8081"

// 使用axios拦截器，为所有请求加上token
axios.interceptors.request.use(function (config) {
  // 获取token
  var token = sessionStorage.getItem("token");
  if (token) {
    config.headers.token = token;
  }
  return config;
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 获取请求路径
  const path = to.path;

  // 放行路径
  if (path === "/Login") {
    return next();
  }

  // 获取token值 判断是否登录
  const token = sessionStorage.getItem("token");
  // 如果token存在，放行
  if (token) {
    return next();
  }

  // 如果token不存在，重定向到登录页面
  return next("/Login");
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
