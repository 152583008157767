<template>
  <div>
    <div class="block" align="center">
      <el-date-picker
          v-model="queryParamVo.date"
          type="month"
          @change="init"
          placeholder="选择月">
      </el-date-picker>

      <el-button type="success" style="margin-left: 20px" @click="refresh">刷新</el-button>
    </div>
    <el-row>
      <el-col :span="24" style="float: left;margin-top: 30px">
        <span style=" letter-spacing: 2px; font-size: 24px"><center>{{ currentDate }}进帐总金额{{total}}元</center></span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div id="container">
          <!-- 存放图形的div -->
          <div id="moneyStatics"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>

export default {
  name: "MoneyEcharts",
  data() {
    return {
      queryParamVo: {
        date: "",
      },
      currentDate: "",
      total: 0,
    }
  },
  mounted() {
    this.init();
    this.setDate();
  },
  methods: {
    setDate() {
      this.currentDate = new Date();
      const options = { year: 'numeric', month: '2-digit', timeZone: 'Asia/Shanghai' };
      const formattedDate = new Intl.DateTimeFormat('zh-CN', options).format(this.currentDate);
      this.currentDate=formattedDate;
    },
    init() {
      this.currentDate = this.queryParamVo.date;
      const options = { year: 'numeric', month: '2-digit', timeZone: 'Asia/Shanghai' };
      const formattedDate = new Intl.DateTimeFormat('zh-CN', options).format(this.currentDate);
      this.currentDate=formattedDate;
      this.initChar();
    },
    refresh() {
      this.queryParamVo.date = this.currentDate;
      this.init();

    },

    initChar() {
      this.$http.post("/salemanager/findIncome", this.queryParamVo).then((res => {
        this.total=res.data.data.totalMoney;
        const myChart = this.$echarts.init(document.getElementById('moneyStatics'));
        myChart.setOption({
          title: {
            text: "商家进帐金额统计图",
          },
          //提示信息
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: res.data.data
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: res.data.data.xdatas,
            axisLabel: {
              rotate: 30, //让文本倾斜
              interval: 0,
              textStyle: {
                color: "#23262e",
                fontSize: 12
              },
            },
          },
          yAxis: {},
          series: [
            {
              name: res.data.data.ydatas,
              barWidth: "25%",
              type: 'bar',
              data: res.data.data.ydatas,
              //柱状图具体数字
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    fontSize: 13,
                    fontWeight: "bold",
                    marginTop: 15,
                    position: "top",
                  }
                }
              }
            }
          ]
        })
      }))
    },

  }
}
</script>
<style scoped>
#moneyStatics {
  height: 500px;
  width: 900px;
}

#container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
</style>
