<template>
  <div id="app">
    <div id="searchData">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="searchForm.userName" placeholder="——请输入手机号搜索——"
                    class="center-text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.nickName" placeholder="——请按照用户昵称搜索——"
                    class="center-text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="searchStore" icon="el-icon-search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="flushedStation" icon="el-icon-refresh">刷新</el-button>
        </el-form-item>
      </el-form>

    </div>
    <div id="myTable">
      <el-table
          :data="tableData"

          style="width: 100% ;">
        <!-- 序号 -->
        <el-table-column
            type="index"
            :index="indexMethod"
        >
        </el-table-column>
        <el-table-column
            prop="newAvatar"
            label="图像"
        >
          <template slot-scope="scope">
            <el-image :src="scope.row.newAvatar" style="width: 70px; height: 70px"></el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="userName"
            label="手机号"
            width="120"
        >

        </el-table-column>
        <el-table-column
            prop="num"
            label="跳转网页次数"
            width="120"
        >

        </el-table-column>
        <el-table-column width="150" prop="businessImages" label="营业执照">
          <template slot-scope="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.businessImages"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-src-list="[scope.row.businessImages]"
                :initial-index="0"
                fit="cover"
            />
          </template>
        </el-table-column>
        <el-table-column
            prop="nickName"
            label="昵称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="sex"
            label="性别">
          <template slot-scope="scope">
            <span
                effect="dark"
                v-if="scope.row.sex==0"
            >未知
            </span>
            <span
                v-if="scope.row.sex==1"
            >男
            </span>
            <span
                v-if="scope.row.sex==2"
            >女
            </span>
          </template>
        </el-table-column>
        <el-table-column
            prop="isIdcard"
            label="实名"
            width="180px">
          <template slot-scope="scope">

            <span
                v-if="scope.row.isIdcard==1"
            >已认证

  <el-popover
      placement="right"
      width="700"
      trigger="click"
  >
    <el-table :data="gridData">
      <el-table-column width="150" prop="name" label="姓名"></el-table-column>
      <el-table-column width="300" prop="address" label="地址"></el-table-column>
      <el-table-column width="100" prop="gender" label="性别"></el-table-column>
      <el-table-column width="100" prop="nationality" label="民族"></el-table-column>
      <el-table-column width="100" prop="authority" label="办理地址"></el-table-column>
      <el-table-column width="300" prop="frontImage" label="正面像">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.frontImage"
              :zoom-rate="1.2"
              :max-scale="7"
              :min-scale="0.2"
              :preview-src-list="srcList"
              :initial-index="0"
              fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column width="300" prop="backImage" label="背面像">
        <template slot-scope="scope">
           <el-image
               style="width: 100px; height: 100px"
               :src="scope.row.backImage"
               :zoom-rate="1.2"
               :max-scale="7"
               :min-scale="0.2"
               :preview-src-list="srcList"
               :initial-index="1"
               fit="cover"
           />
        </template>
      </el-table-column>
      <el-table-column width="200" prop="validPeriod" label="有效时间"></el-table-column>

      <el-table-column width="200" prop="birth" label="生日"></el-table-column>
    </el-table>
    <el-button slot="reference" @click="getActual(scope.row.openid)"
               style="width: 80px;border: none">查看详情</el-button>
  </el-popover>

            </span>
            <span
                v-if="scope.row.isIdcard==0"
            >未认证
            </span
            >
          </template>
        </el-table-column>
        <el-table-column
            prop="record"
            label="发送记录"
            width="250"
        >
          <template slot-scope="scope">
            {{ scope.row.record }}次

            <el-popover
                placement="right"
                width="700"
                trigger="click"
                popper-class="el_popover_class"
            >
              <el-table :data="records" style="width: 100%;">
                <el-table-column width="150" prop="suid" label="订单号"></el-table-column>
                <el-table-column width="190" prop="createTime" label="发送时间"></el-table-column>
                <el-table-column width="100" prop="status" label="发送状态"  :formatter="getStatusCode" ></el-table-column>
                <el-table-column label="发送量" width="100">
                  <template v-slot="scope">
                    {{ counts[scope.$index] }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="150">
                  <template slot-scope="scope">
                    <el-button @click="getDetail(scope.row)" icon="el-icon-search" style="width: 80px;border: none">查看记录
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <el-button slot="reference" @click="getRecords(scope.row.openid)" style="width: 80px;border: none">
                查看记录
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            prop="ip"
            label="最后登录ip"
            width="150">
        </el-table-column>
        <el-table-column
            prop="ini"
            label="免费发送次数"
            width="180">
          <template slot-scope="scope">
            {{ scope.row.ini }}
            <!--
   弹出框
   -->
            <el-popover
                placement="top"
                width="150"
                :v-model="isAllow[scope.row.openid]"
            >
              充值条数：<input type="number" v-model="rechargeAmount" placeholder="输入充值条数"
                              style="width: 50px;margin-bottom: 5px"/>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="cancel(scope.row.openid)">取消</el-button>
                <el-button type="primary" size="mini" @click="confirmRecharge(scope.row.openid)">确定</el-button>
              </div>
              <el-button slot="reference" style="width: 80px; border: none" class="el-icon-plus"
                         @click="openPopover(scope.row.openid)">充值
              </el-button>
            </el-popover>

          </template>
        </el-table-column>
        <el-table-column
            prop="rest"
            label="可用发送条数"
            width="180">
          <template slot-scope="scope">
            {{ scope.row.rest }}
            <!--
   弹出框
   -->
            <el-popover
                placement="top"
                width="150"
                :v-model="istrue[scope.row.openid]"
            >
              充值条数：<input type="number" v-model="mount" placeholder="输入充值条数"
                              style="width: 50px;margin-bottom: 5px"/>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="exit(scope.row.openid)">取消</el-button>
                <el-button type="primary" size="mini" @click="setRest(scope.row.openid)">确定</el-button>
              </div>
              <el-button slot="reference" style="width: 80px; border: none" class="el-icon-plus"
                         @click="clickPower(scope.row.openid)">充值
              </el-button>
            </el-popover>

          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="270">
          <template slot-scope="scope">
            <el-button type="primary" @click="editStation(scope.row)" icon="el-icon-edit-outline">编辑
            </el-button>
            <el-button type="danger" @click="deleteByStation(scope.row.openid)"
                       icon="el-icon-remove-outline">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div id="pagination">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="searchForm.currentPage"
          :page-sizes="[1, 5, 10, 20]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <div id="dialog">
      <!--添加和编辑的对话框-->
      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <el-form ref="addEditform" :model="addEditform" :rules="rules" label-width="80px">
          <el-form-item label="手机号" prop="userName">
            <el-input v-model="addEditform.userName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="nickName">
            <el-input v-model="addEditform.nickName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="头像">
            <!--:headers="head"-->
            <el-upload
                class="avatar-uploader"
                action="//192.168.28.103:8081/upload/upload"
                :headers="head"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="addEditform.newAvatar" :src="addEditform.newAvatar" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="用户性别" label-width="80">
            <el-radio-group v-model="addEditform.sex">
              <el-radio label="0">未知</el-radio>
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="cancelbutt">取 消</el-button>
    <el-button type="primary" @click="enterAdd" v-if="actor=='add'">确定添加</el-button>
    <el-button type="primary" @click="enterEdit" v-if="actor=='edit'">确定编辑</el-button>
  </span>
      </el-dialog>


      <!--
      发送内容及发送号码

      -->
    </div>
    <el-drawer
        :title="title"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleCloses"
    >
      <el-form :inline="true" :model="typeForm" class="demo-form-inline">
        <el-form-item>
          <el-select
              v-model="typeForm.type"
              placeholder="发送类型"
              class="center-text"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>

          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="searchPhoneBytype" icon="el-icon-search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="flush" icon="el-icon-refresh">刷新</el-button>
        </el-form-item>
      </el-form>
      <ul>
        <li v-for="item in phones" :key="item.id">{{ item.phone }}</li>
      </ul>

    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "Station",
  data() {
    return {
      options: [
        {label: "文件上传", value: 0},
        {label: "手动", value: 1},



      ],
      doubleRow: "",
      isAllow: {},  // 存储每行的弹出框状态
      istrue: {},  // 存储每行的弹出框状态
      rechargeAmount: 0,  // 存储输入的充值条数
      mount: 0,  // 存储输入的充值条数
      direction: 'rtl',
      //发送量
      counts: [],
      //用户发送成功记录
      records: [],
      //预览图片地址
      srcList: [],
      srcListx: [],
      //实名信息
      gridData: [],
      //控制抽屉的显示
      drawer: false,
      //抽屉的标题
      title: "",
      //发送的电话号码
      phones: [],
      head: '',
      //文件上传路径
      searchForm: {
        //分页
        currentPage: 1,
        pageSize: 5,
        userName: '',
        nickName: ''
      },
      typeForm: {
        type: ""
      },

      tableData: undefined,
      total: 0,
      //确认按钮类型
      actor: '',
      //门店信息
      stores: [],
      rules: {
        storeId: [{required: true, message: '门店名称不能为空', trigger: 'change'}],
        stationName: [{required: true, message: '库不能为空', trigger: 'change'}],
      },
      addEditform: {},
      dialogVisible: false,
    }
  },
  created() {
    this.loadStation();
    this.head = {
      token: sessionStorage.getItem("token")
    }
  },
  methods: {
    getStatusCode(row) {
      switch (row.status) {
        case 0:
          return "未知状态";
        case 1:
          return "发送成功";

        default:
          return "发送失败";
      }
    },
    flush() {
      this.typeForm.type = ""
      this.getDetail(this.doubleRow)
    },
    searchPhoneBytype() {
      this.getDetail(this.doubleRow)
    },
    openPopover(openid) {
      this.isAllow[openid] = true;// 打开对应行的弹出框
    },
    cancel(openid) {

      this.isAllow[openid] = false;  // 关闭对应行的弹出框
      this.rechargeAmount = 0;  // 清空输入
    },
    confirmRecharge(openid) {

      // 处理充值逻辑
      if (this.rechargeAmount <= 0) {
        this.$message.error('请输入有效的充值条数');
        return;
      }

      // 假设你有一个 API 调用来处理充值

      // 这里可以添加 API 调用的逻辑
      // 例如: this.$axios.post('/api/recharge', { suid, amount: this.rechargeAmount })
      this.$http.put("/userDy/updateIni?openid=" + openid + "&ini=" + this.rechargeAmount).then(resp => {
        if (resp.data.code === 200) {
          this.isAllow[openid] = false;  // 关闭弹出框
          this.rechargeAmount = 0; // 清空输入
          this.loadStation()
          this.$message.success(resp.data.msg);  // 显示成功消息
        } else {
          this.$message.error(resp.data.msg);  // 显示失败消息
        }
      })

    },
    clickPower(openid) {
      this.istrue[openid] = true;  // 打开对应行的弹出框
    },
    exit(openid) {
      this.istrue[openid] = false;  // 关闭对应行的弹出框
      this.mount = 0;  // 清空输入
    },
    setRest(openid) {
      // 处理充值逻辑
      if (this.mount <= 0) {
        this.$message.error('请输入有效的充值条数');
        return;
      }

      // 假设你有一个 API 调用来处理充值

      // 这里可以添加 API 调用的逻辑
      // 例如: this.$axios.post('/api/recharge', { suid, amount: this.rechargeAmount })
      this.$http.put("/userDy/updateRest?openid=" + openid + "&rest=" + this.mount).then(resp => {
        console.log(resp)
        if (resp.data.code === 200) {
          this.istrue[openid] = false;  // 关闭弹出框
          this.mount = 0; // 清空输入
          this.loadStation()
          this.$message.success(resp.data.msg);  // 显示成功消息
        } else {
          this.$message.error(resp.data.msg);  // 显示失败消息
        }
      })
    },
//关闭右侧对话框
    handleCloses(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    //显示索引
    indexMethod(index) {
      return index + 1;
    },
    getRecords(openid) {
      this.$http.get("/userDy/getRecors?openid=" + openid).then(resp => {
        if (resp.data.code === 200) {
          this.records = resp.data.data.userSuccessList;
          this.counts = resp.data.data.counts
        }
      })
    },
    //获得发送订单的内容和发送号码
    getDetail(row) {
      this.doubleRow = row
      this.drawer = true
      this.$http("/userDy/getDetail?suid=" + row.suid+"&type="+this.typeForm.type).then(resp => {
        console.log(resp)
        if (resp.data.code === 200) {
          this.title = resp.data.data[0].conment
          this.phones = resp.data.data
        }
      })
    },
    //加载Station
    loadStation() {
      this.$http.post("/userDy/getUsers", this.searchForm).then(resp => {
        this.tableData = resp.data.data.records;
        this.total = resp.data.data.total;
        console.log(resp.data.data.records);
        console.log(this.tableData)
        this.srcListx = [this.tableData.businessImages];

      })
    },
    //更改每页显示条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.loadStation()
    },
    //更改当前页码数
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.loadStation()
    },
    //对话框关闭方法
    handleClose() {
      this.dialogVisible = false
      this.addEditform = {}
      this.loadStation();
    },
    //取消按钮
    cancelbutt() {
      this.dialogVisible = false
      this.addEditform = {};
      this.loadStation();
    },

    //刷新按钮
    flushedStation() {
      this.searchForm.userName = '';
      this.searchForm.nickName = '';

      this.loadStation();
    },
    enterEdit() {
      this.$refs.addEditform.validate((valid) => {
        if (valid) {
          this.actor = "";
          this.$http.put("/userDy/editUser", this.addEditform).then(resp => {
            if (resp.data.code === 200) {
              this.$message({
                type: 'success',
                message: '更新成功!'
              });
              this.loadStation();
            }
          })
          this.addEditform = {}
          this.dialogVisible = false;
        }
      })
    },
    //添加按钮的方法
    addStation() {
      this.actor = "add";
      this.dialogVisible = true;
    },
    //编辑按钮
    editStation(row) {
      this.actor = "edit"
      this.dialogVisible = true;
      this.addEditform = row;
      this.addEditform.sex = row.sex + '';
    },

    searchStore() {
      this.pageSize = 1;
      this.loadStation();
    },
    deleteByStation(row) {
      console.log(row)
      this.$confirm('是否删除该用户？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete("/userDy/deleteByOpenid?openid=" + row).then(resp => {
          if (resp.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.searchForm.currentPage = 1;
            this.loadStation();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //获得用户个人信息
    getActual(openid) {
      this.srcList = null;
      this.gridData = null;
      this.$http.get("/userDy/getActinfo?openid=" + openid).then(resp => {

        if (resp.data.code === 200) {
          this.gridData = [resp.data.data];
          this.srcList = [resp.data.data.frontImage, resp.data.data.backImage]
        }
      })
    },
    //图片上传的方法
    handleAvatarSuccess(res, file) {
      this.addEditform.picture = res.data;
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith('image/');
      const isJPGorPNG = isImage && (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPGorPNG) {
        this.$message.error('上传头像图片只能是 JPG或者是PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPGorPNG && isLt2M;
    },
  }
}
</script>

<style scoped>
#searchData {
  float: left;
}

/*上传图片的样式*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  align-items: center;
  border-color: #409EFF;
}
.el-table .cell{
  text-align: center;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 70px;
  padding-top: 50px;
}

.avatar {
  width: 120px;
  height: 120px;

  display: block;
}

.center-text {
  /*text-align: center;*/
}

style scoped >
.demo-image__error .image-slot {
  font-size: 30px;
}

.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}

.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}

</style>
<style scoped>
.demo-image__error .image-slot {
  font-size: 30px;
}

.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}


.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
</style>
