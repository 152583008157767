<template>
  <div style="display: flex;margin-top: 150px; justify-content: space-between; ">
    <div class="tiltle">
      <el-row>
        <el-col :span="24" style="float: left">
          <span style="font-size: 24px"><center>微信小程序总注册量 :{{ all }} 人</center></span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div id="container">
            <!-- 存放图形的div -->
            <div id="userStatic"></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="text">
      <el-row>
        <el-col :span="24" style="float: left">
          <span style="font-size: 24px"><center>抖音小程序总注册量 :{{ allDy }} 人</center></span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div id="container">
            <!-- 存放图形的div -->
            <div id="DyuserStatic"></div>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>
<script>

export default {
  name: "RegisterEcharts",
  data() {
    return {
      all: 0,
      allDy: 0,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initChar();
      this.iniCharDy();
    },
    initChar() {
      this.$http.get("/salemanager/register").then((res => {
        console.log(res);
        //统计总数
        this.all = res.data.data.counts;

        const myChart = this.$echarts.init(document.getElementById('userStatic'));
        myChart.setOption({
          title: {
            text: "微信用户注册量统计图",
          },
          //提示信息
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['注册量']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            data: res.data.data.xdatas
            //res.data.data.registerTime
          },
          yAxis: {},
          series: [
            {
              name: "注册量",
              barWidth: "20%",
              type: 'bar',
              data: res.data.data.ydatas,
              //res.data.data.count

            }
          ]
        })
      }))
    },
    iniCharDy() {
      this.$http.get("/salemanager/registerDy").then((res => {
        //统计总数
        this.allDy = res.data.data.counts;

        const myChart = this.$echarts.init(document.getElementById('DyuserStatic'));
        myChart.setOption({
          title: {
            text: "用户注册量统计图",
          },
          //提示信息
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['注册量']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            data: res.data.data.xdatas
            //res.data.data.registerTime
          },
          yAxis: {},
          series: [
            {
              name: "注册量",
              barWidth: "20%",
              type: 'bar',
              data: res.data.data.ydatas,
              //res.data.data.count

            }
          ]
        })
      }))
    },

  }
}
</script>
<style scoped>
#userStatic {
  height: 500px;
  width: 800px;
}
#DyuserStatic {
  height: 500px;
  width: 800px;
}

#container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
</style>
